import React from 'react';

function ValueProps() {
  return (
    <div className="header__hero-footer">
      <div className="container">
        <div className="row no-gutters">
          <div className="col value-prop">
            <div className="row no-gutters">
              <div className="col-md-auto">
                <div className="icon">
                  <svg viewBox="0 0 512 512" width="25" height="25" xmlns="http://www.w3.org/2000/svg"><path d="m57.7 193 9.4 16.4c8.3 14.5 21.9 25.2 38 29.8l57.9 16.5c17.2 4.9 29 20.6 29 38.5v39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9v39c0 15.6 14.9 26.9 29.9 22.6 16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7v-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1h-43.1c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5 4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5.3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3-92.8 0-171.5 60.9-198.2 145zm406.3 63c0-36.8-9.6-71.4-26.4-101.5l-25.6 10.3c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zm-464 0a256 256 0 1 1 512 0 256 256 0 1 1 -512 0z" fill="#fff" /></svg>
                </div>
              </div>
              <div className="col-md">
                <div className="header__hero-icon-text d-inline-block">
                  <div>Wide-Ranging Inventory</div>
                  <span className="d-none d-md-inline">From affordable pop-ups to luxury motorhomes</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col value-prop">
            <div className="row no-gutters">
              <div className="col-md-auto">
                <div className="icon">
                  <svg viewBox="0 0 512 512" width="25" height="25" xmlns="http://www.w3.org/2000/svg"><path d="m327.5 85.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8l56.5 21.2 21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5l21.2-56.5 56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8l-56.5-21.2-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5l-21.2 56.5zm-318.2 154.8c-5.7 2.6-9.3 8.3-9.3 14.6s3.6 11.9 9.3 14.5l17 7.9 8.1 3.7.6.3 88.3 40.8 40.8 88.2.3.6 3.7 8.1 7.9 17.1c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l7.9-17.1 3.7-8.1.3-.6 40.8-88.3 88.3-40.7.6-.3 8.1-3.7 17.1-7.9c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5l-17.1-7.9-8.1-3.7-.6-.3-88.3-40.8-40.7-88.3-.3-.6-3.7-8.2-7.9-17.1c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3l-7.9 17.1-3.7 8.1-.3.6-40.8 88.3-88.3 40.8-.6.3-8.1 3.7zm83 14.5 51.2-23.6c10.4-4.8 18.7-13.1 23.5-23.5l23.6-51.2 23.6 51.2c4.8 10.4 13.1 18.7 23.5 23.5l51.2 23.6-51.2 23.6c-10.4 4.8-18.7 13.1-23.5 23.5l-23.6 51.2-23.6-51.2c-4.8-10.4-13.1-18.7-23.5-23.5l-51.2-23.5zm291.7 129.5-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8l56.5 21.2 21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5l21.2-56.5 56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8l-56.5-21.2-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5z" fill="#fff" /></svg>
                </div>
              </div>
              <div className="col-md">
                <div className="header__hero-icon-text d-inline-block">
                  <div>Largest RV Rental Marketplace</div>
                  <span className="d-none d-md-inline">Thousands of 5 star reviews from happy customers</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col value-prop">
            <div className="row no-gutters">
              <div className="col-md-auto">
                <div className="icon">
                  <svg viewBox="0 0 512 512" width="25" height="25" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c4.6 0 9.2 1 13.4 2.9l188.3 79.9c22 9.3 38.4 31 38.3 57.2-.5 99.2-41.3 280.7-213.6 363.2-16.7 8-36.1 8-52.8 0-172.3-82.5-213.1-264-213.6-363.2-.1-26.2 16.3-47.9 38.3-57.2l188.4-79.9c4.1-1.9 8.7-2.9 13.3-2.9zm0 66.8v378c138-66.8 175.1-214.7 176-303.4z" fill="#fff" /></svg>
                </div>
              </div>
              <div className="col-md">
                <div className="header__hero-icon-text d-inline-block">
                  <div>Secure & Most Trusted</div>
                  <span className="d-none d-md-inline">24/7 roadside assistance on every booking</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ValueProps;
