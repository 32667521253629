/* eslint-disable global-require */
import styled from 'styled-components';
import OpenModalButton from 'components/OpenModalButton';
import { Button } from '@rvshare/core-ui';

import Topos from 'images/rv_finder/bg-pattern.png';

import TravelerSelect from 'search/components/SearchHeader/TravelerSelect';
import { TravelerSelectWrapper } from 'search/components/SearchHeader/styles/SearchInputs';
import DateRangePickerInput from '../DateRangePickerInput';
import BaseDestinationInput from '../DestinationInput';

const CustomHomeColor = '#1C1C11';
const NewDesignLightGray = '#d9d9d9';
// const NewDesignGray = '#919191';

export const SearchBoxContainer = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  min-width: 325px;
  padding: 0;

  ${({ theme }) => theme.medium`
    font-size: 1rem;
    max-width: 570px;
    padding-top: 2.75rem;
  `};

  ${({ theme }) => theme.medium`
    padding: 1.75rem;
  `};
`;

export const Header = styled.h1`
  color: ${props => props.theme.colors.bodyColor};
  font-size: 1.65rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0;
  text-align: center;
  padding: 0;

  ${({ theme }) => theme.medium`
    font-size: 2rem;
    padding: 0 0 .5rem;
    text-align: left;
  `};
`;

export const InputWrapper = styled.div`
  background: #fff;
  border-radius: 0.25rem;
  padding: 1rem;
  ${({ theme }) => theme.medium`
    background: none;
    padding: 0;
  `};
`;

export const DestinationWrapper = styled.div`
  align-items: center;
  border: 1px solid ${NewDesignLightGray};
  box-shadow: 0px 0px 0px 1px ${NewDesignLightGray};
  border-radius: 4px;
  display: flex;
  height: 50px;
  width: 100%;
`;

export const SearchIconWrapper = styled.div`
  padding: 8px;
  width: 22px;
`;

export const DestinationInput = styled(BaseDestinationInput)`
  flex: 1;
  position: relative;
  text-align: left;
  z-index: 998;
  background: #fff;

  .SearchBox__location__Autocomplete {
    display: flex;
  }

  input {
    margin-top: 0.1rem;
    color: ${CustomHomeColor};
    font-weight: bold;
    border: none;
    box-shadow: none !important;
    font-size: 1rem;
    outline: none;
    padding: 1rem 1rem 1rem 0.5rem;
    ::placeholder {
      color: #919191;
      font-weight: normal;
      opacity: 1;
    }
  }

  .SearchBox__location__AutocompleteContainer {
    background-color: #fff;
    border: 1px solid #555;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 15;
    padding-bottom: 30px;
    background-repeat: no-repeat;
    background-size: auto 15px;
    background-position: bottom 10px right 10px;
  }

  .SearchBox__location__AutocompleteItem {
    background-color: #fff;
    padding: 10px;
    color: #555;
    cursor: pointer;

    &.active {
      background-color: #f4f4f4;
    }
  }
`;

export const CancelIconWrapper = styled.div`
  margin-top: 0.20rem;
  margin-right: 0.6rem;
  color: #78797A;
  cursor: pointer;
`;

export const MobileDateTrigger = styled(OpenModalButton)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;

  ${({ theme }) => theme.medium`
    display: none;
  `}
`;

function getDatePickerIcon() {
  return require('images/icons/calendar-alt-regular.svg');
}

export const DatePickerWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  ${({ theme }) => theme.medium`
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  `}
`;

export const DatePicker = styled(DateRangePickerInput)`
  .DateRangePicker_picker {
    right: -230px !important;
  }

  .DateRangePickerInput_arrow_svg {
    fill: ${CustomHomeColor};
    height: 20px;
    width: 20px;
  }

  .DateRangePickerInput {
    border: 1px solid ${NewDesignLightGray};
    box-shadow: 0px 0px 0px 1px ${NewDesignLightGray};
    border-radius: 4px;
    padding-left: 1.25rem;

    ::before {
      background: url(${getDatePickerIcon}) no-repeat;
    }

    ${({ theme }) => theme.medium`
      padding-left: 0;

      ::before {
        background: none;
      }
    `};
  }

  .DateRangePicker_1 {
    width: 97%;
  }

  .DateInput {
    width: 42%;

    &:first-of-type {
      padding-right: 0.125rem;
    }

    &:last-of-type {
      .DateInput_input {
        padding-left: 0.25rem;
      }
    }

    ${({ theme }) => theme.medium`
      .DateInput_input {
        padding-left: 2rem;
      }

      &:last-of-type {
        .DateInput_input {
          padding-left: 2rem;
        }
      }

      ::before {
        background: url(${getDatePickerIcon}) no-repeat;
      }
    `};
  }

  .DateInput::before, .DateRangePickerInput::before {
    content: '';
    background-size: contain;
    position: absolute;
    height: 17px;
    width: 15px;
    top: 6px;
    left: 0;
    margin: 10px;
    z-index: 1;
  }

  .DateInput_input {
    color: ${CustomHomeColor};
    font-weight: bold;
    cursor: pointer;
    font-size: 1rem;
    height: 3.125rem;
    padding-right: 0;
    ::placeholder {
      color: ${CustomHomeColor};
      font-weight: normal;
      opacity: 1;
    }
  }
`;

export const RowWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.medium`
    padding-top: 0;
  `}
`;

export const VehicleTowingMatchLabel = styled.p`
  font-size: 0.875rem;
  color: #414141;
  margin-bottom: 0.75rem;

  ${({ theme }) => theme.medium`
    font-size: 1rem;
  `}
`;

export const VehicleTowingMatchWrapper = styled.div`
  display: grid;
  max-width: fit-content;
  grid-gap: 2%;
  grid-auto-flow: column;
  margin-bottom: 1.688rem;
  grid-template-columns: 28% 32% 36%;

  ${({ theme }) => theme.medium`
    grid-template-columns: 22% 32% 42%;
  `}
`;

export const TravelerSelectWrapperStyled = styled(TravelerSelectWrapper)`
  margin-top: 0.95rem;
  ${({ theme }) => theme.medium`
    margin-top: 1.2rem;
  `}
`;

export const TravelerSelectStyled = styled(TravelerSelect)`
  border: 1px solid ${NewDesignLightGray};
  border-radius: 4px;
  height: 54px;

  .Button {
    width: 136px;
    border-radius: inherit;
    align-items: center;

    // This stops the background color from changing on hover
    &:hover {
      background-color: transparent;
    }
  }

  // This prevents the dropdown's background from changing to blue when it's open and not focused
  &.open {
    .Button {
      background-color: white;
    }
  }


  .IconAndLabel {
    align-items: center;
    display: flex;

    .TravelersIcon {
      color: ${CustomHomeColor};
      font-size: 14px;
    }
    .InputLabel {
      color: ${CustomHomeColor};
      font-weight: 400 !important;
      font-size: 1rem !important;
    }
    .SelectedItemText {
      color: ${CustomHomeColor};
      margin-left: 3px;
      font-size: 1rem !important;
      font-weight: bold !important;
    }
  }
  .ArrowIcon {
    color: #78797A !important;
    margin-top: 3px;
    margin-left: 5px;
  }
`;

export const DeliveryTextTip = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.primaryBlue};
  ${({ theme }) => theme.medium`
    font-size: 16px;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
`;

export const RvFinderContainer = styled.aside`
  margin: 1rem;
  text-align: left;
  background-image: url(${Topos});
  background-size: cover;
  background-position: center top;
  background-color: #fff;
  border-radius: 0.5rem;
  min-width: 325px;
  display: flex;
  padding: 1.25rem;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
  ${({ theme }) => theme.medium`
    margin: 1rem 0 0 0;
  `};
`;

export const RvFinderHeader = styled.div`
  color: ${props => props.theme.colors.grey800};
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: left;
  padding: 0 0 .5rem;
`;

export const RvFinderAction = styled.a`
  display: flex;
  color: ${props => props.theme.colors.primaryBlue};
  font-size: 1.125rem;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    top: 0;
    left: 0;
  }
`;
