import React from 'react';
import styled from 'styled-components';
import { Flex } from 'core/ui';

import AllRvsIcon from './Icons/AllRVsIcon';
import DrivablesIcon from './Icons/DrivablesIcon';
import TowablesIcon from './Icons/TowablesIcon';
import DestinationDeliveryIcon from './Icons/DestinationDeliveryIcon';

const searchTypes = ['all_rvs', 'drivables', 'towables', 'delivery'];

const SearchTypeContainer = styled(Flex)`
  margin-top: 1.265rem;
  border-bottom: 1px solid #d8d8d8;
  justify-content: space-between;
  gap: 10px;

  ${({ theme }) => theme.medium`
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: none;
  `};
`;

const SearchTypeButton = styled.button`
  border: none;
  background-color: #fff;
  color: ${props => (props.active ? props.theme.colors.primaryBlue : '#414141')};
  font-size: 14px;
  font-weight: 600;
  border-bottom: ${props => props.active && `3px solid ${props.theme.colors.primaryBlue}`};
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  text-align: center;
  line-height: 1.25rem;
  align-items: center;
  &:focus {
    outline: none;
  }
  ${({ theme }) => theme.medium`
    font-size: 15px;
    background-color: ${props => (props.active ? '#414141' : '#e6e6e6')};
    color: ${props => (props.active ? '#fff' : '#414141')};
    border: none; 
    padding: 22px 24px 8px;
    border-radius: 10px;
    span {
      margin-top: 14px;
    }
  `};
`;

const IconWrapper = styled.div`
  height: ${props => (props.isMobile ? '23px' : '28px')}; // must match largest icon, which is 'delivery'
  display: flex;
  justify-content: center;
  align-items: center;
`

const setIconAndCopy = (searchType, isMobile) => {
  const iconWidth = isMobile ? '45px' : '52px';
  const iconHeight = isMobile ? '21px' : '24px';
  const specialCaseIconWidth = isMobile ? '50px' : '60px';
  const specialCaseIconHeight = isMobile ? '23px' : '28px';

  switch (searchType) {
    case 'all_rvs':
      return { icon: <AllRvsIcon width={iconWidth} height={iconHeight} />, copy: 'All RVs' };
    case 'towables':
      return { icon: <TowablesIcon width={iconWidth} height={iconHeight} />, copy: 'Towables' };
    case 'drivables':
      return { icon: <DrivablesIcon width={iconWidth} height={iconHeight} />, copy: 'Drivables' };
    case 'delivery':
      return {
        icon: <DestinationDeliveryIcon width={specialCaseIconWidth} height={specialCaseIconHeight} />,
        copy: isMobile ? 'Delivery' : 'Destination Delivery',
      };
    default:
      return {};
  }
};

function SearchTypeToggle(props) {
  const { searchType, setSearchType, isMobile, styles } = props;

  return (
    <SearchTypeContainer style={styles}>
      {(searchTypes || []).map(type => {
        const { icon, copy } = setIconAndCopy(type, isMobile);
        return (
          <SearchTypeButton
            key={type}
            onClick={() => setSearchType(type)}
            active={searchType === type}
          >
            <IconWrapper isMobile={isMobile}>{icon}</IconWrapper>
            <span>{copy}</span>
          </SearchTypeButton>
        );
      })}
    </SearchTypeContainer>
  );
}

export default React.memo(SearchTypeToggle);
