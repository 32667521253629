import React from 'react';
import { Icon } from '@rvshare/core-ui';

import RvTrailer from 'images/home/rv_trailer.svg';

import { RvFinderContainer, RvFinderHeader, RvFinderAction } from './styles';

export default function RvFinderLink() {
  return (
    <RvFinderContainer aria-labelledby="rv-finder-header">
      <div>
        <RvFinderHeader id="rv-finder-header">Not sure where to start?</RvFinderHeader>
        <RvFinderAction href="/rv-finder">
          Use Our RV Finder <Icon icon="chevronRightSmall" />
        </RvFinderAction>
      </div>
      <div>
        <img alt="RV Finder Trailer" src={RvTrailer} />
      </div>
    </RvFinderContainer>
  );
}
