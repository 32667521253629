import { differenceInCalendarDays, parseISO } from 'date-fns';
import SafeTracking from 'tracking/SafeTracking';

export const trackHomepageSearchExecuted = ({
  destination,
  travelDates,
  numberOfTravelers,
  searchType,
  towingVehicle,
  optimizelyDecision = {},
}) => {
  const location = destination?.location;
  const { startDate, endDate } = travelDates || {};
  const trackedVehicle =
    searchType === 'towables'
      ? {
          ...towingVehicle,
        }
      : null;

  const rentalLength = startDate && endDate ? endDate.diff(startDate, 'days') : null;
  const daysFromRental = startDate ? differenceInCalendarDays(parseISO(startDate), new Date()) : null;

  const startDateFormatted = startDate && startDate.format('LL');
  const endDateFormatted = endDate && endDate.format('LL');

  SafeTracking.track(
    'Homepage Search Executed',
    {
      locationEntered: location,
      startDate: startDateFormatted || '',
      endDate: endDateFormatted || '',
      guests: numberOfTravelers,
      rentalLength,
      daysFromRental,
      searchType,
      towingVehicle: {
        year: trackedVehicle?.year,
        make: trackedVehicle?.make,
        model: trackedVehicle?.model,
      },
      ...optimizelyDecision,
    },
    {
      rvsIntegrations: { all: false, segment: true },
    }
  );
};
