/* eslint-disable no-nested-ternary */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'formik';
import range from 'array-range';

import Icon from 'core/ui/Icon';
import { useDevice } from 'core/hooks/useDevice';
import { trackHomepageSearchExecuted } from 'tracking/search/trackHomepageSearchExecuted';

import useMakes from 'search/hooks/useMakes';
import useModels from 'search/hooks/useModels';
import useDataset from 'search/hooks/useDataset';
import { DownshiftAutocomplete } from 'components/DownshiftAutocomplete';
import ExplanationPopover from 'components/ExplanationPopover2';
import ModelYearDisclaimer from 'search/components/ModelYearDisclaimer';
import withHitchTheme from 'core/withHitchTheme';
import {
  ButtonWrapper,
  DatePicker,
  DatePickerWrapper,
  DestinationInput,
  DestinationWrapper,
  InputWrapper,
  MobileDateTrigger,
  SearchIconWrapper,
  SubmitButton,
  TravelerSelectStyled,
  TravelerSelectWrapperStyled,
  RowWrapper,
  VehicleTowingMatchWrapper,
  VehicleTowingMatchLabel,
  DeliveryTextTip,
  CancelIconWrapper,
} from './styles';

function Inputs({
  handleChange,
  className,
  buttonColor,
  searchType,
  formik: { values, setFieldValue, setValues },
}) {
  const { isMobile } = useDevice();

  const truckDataYears = range(2005, 2024) // range is exclusive of second value
    .map(year => ({
      value: year,
      label: year,
    }))
    .reverse();

  const { makes, makesLoading } = useMakes(values.towingVehicle.year);
  const { models, modelsLoading } = useModels(values.towingVehicle.year, values.towingVehicle.make);
  const vehicleMatchOnChange = field => {
    const value = (field.value || '').toString();

    // if the year changes, clear out previous make/model
    if (field.name === 'towingVehicle.year') {
      setValues({
        ...values,
        towingVehicle: {
          year: value,
          make: '',
          model: '',
        },
      });

      return;
    }

    // if the make changes, clear out previous model
    if (field.name === 'towingVehicle.make') {
      setValues({
        ...values,
        towingVehicle: {
          ...values?.towingVehicle,
          make: value,
          model: '',
        },
      });
      return;
    }

    setFieldValue(field.name, value);
    handleChange(field.name, value);
  };

  // grab interact rv data from custom hook
  const { data: vehicleTowingMatchData, dataLoading, hasNoMatch } = useDataset(
    values.towingVehicle.year,
    values.towingVehicle.make,
    values.towingVehicle.model
  );

  // if dataset is available create custom vehicle object || undefined
  const updatedTowingVehicle = vehicleTowingMatchData
    ? {
      year: vehicleTowingMatchData.year.toString(),
      make: vehicleTowingMatchData.make,
      model: vehicleTowingMatchData.model,
      towingWeight: vehicleTowingMatchData.max_towing_capacity.toString(),
    }
    : undefined;

  // only trigger if there was a match and we have a completed towing vehicle
  const shouldUpdateTowingVehicle = JSON.stringify(updatedTowingVehicle) && !hasNoMatch;
  useEffect(() => {
    if (shouldUpdateTowingVehicle) {
      // update towing vehicle values with our dataset values
      setValues({
        ...values,
        towingVehicle: updatedTowingVehicle,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateTowingVehicle]);

  const handleClick = () => {
    trackHomepageSearchExecuted({
      destination: values?.destination,
      travelDates: values?.travelDates,
      numberOfTravelers: values?.numberOfTravelers,
      searchType,
      towingVehicle: values?.towingVehicle,
    });
  };

  const loading = makesLoading || modelsLoading || dataLoading;
  const disableMakeModel = !values.towingVehicle.year;
  const disableModel =
    disableMakeModel || (values.towingVehicle.year && !values.towingVehicle.make);
  const disableSubmit = searchType === 'towables' && loading;

  const inputRef = useRef(null);
  const clearSearchInputTextRef = useRef(null);

  const [hasValues, setHasValues] = useState(false);

  const clearDestination = () => {
    clearSearchInputTextRef.current();
    setValues({
      ...values,
      destination: {location: "", place_id: ""},
    });
  };

  useLayoutEffect(() => {
    inputRef.current.focus();
  }, [hasValues]);

  return (
    <InputWrapper className="SearchBox">
      {searchType === 'delivery' && (
        <DeliveryTextTip>
          Owner will deliver the RV to your destination - no driving required.
        </DeliveryTextTip>
      )}
      <DestinationWrapper>
        <SearchIconWrapper>
          <Icon icon="map-marker-alt" />
        </SearchIconWrapper>
        <DestinationInput
          type="SearchBox"
          key={values.destination?.location || 'location'}
          name="destination"
          className={className}
          placeholder={values.destination?.location || 'Pickup location or address'}
          value={values.destination}
          onChange={handleChange}
          inputRef={inputRef}
          setHasValues={setHasValues}
          clearSearchInputTextRef={clearSearchInputTextRef}
        />
        {/* If there is a value in the search box, show the cancel icon */}
        {hasValues &&
          <CancelIconWrapper onClick={clearDestination}>
            <Icon icon="times" />
          </CancelIconWrapper>}
      </DestinationWrapper>


      <RowWrapper>
        <DatePickerWrapper>
          <DatePicker
            type="SearchBox"
            name="travelDates"
            value={values.travelDates}
            onChange={handleChange}
            className={className}
          />
          <MobileDateTrigger
            type="button"
            variant="transparent"
            modal="DatePicker"
            modalProps={{
              startDate: values.travelDates.startDate,
              endDate: values.travelDates.endDate,
              buttonText: 'Save',
              onSubmit: handleChange,
            }}
          >
            <span className="sr-only">Open the travel date picker</span>
          </MobileDateTrigger>
        </DatePickerWrapper>
        <TravelerSelectWrapperStyled>
          <TravelerSelectStyled
            value={values.numberOfTravelers}
            onChange={handleChange}
            inputLarge
          />
        </TravelerSelectWrapperStyled>
      </RowWrapper>

      {searchType === 'towables' && (
        <>
          <VehicleTowingMatchLabel>
            Enter your vehicle information to filter RVs that you can tow (optional)
            <ExplanationPopover
              content={
                <p style={{ textAlign: 'left', margin: 0, padding: '1rem' }}>
                  Results are displayed based on user input, owner-supplied content, third-party
                  manufacturer data, and estimated gross weights. Renters should perform their own
                  research before selecting an RV to rent including determining whether their towing
                  vehicle is compatible with an RV. RVshare is not responsible and shall not be held
                  liable for any listed RVs that do not match a user&apos;s preferred criteria.
                </p>
              }
              placement={isMobile ? 'top' : 'left'}
              trigger="click"
            />
          </VehicleTowingMatchLabel>
          <VehicleTowingMatchWrapper>
            <DownshiftAutocomplete
              selectedValue={values.towingVehicle.year}
              handleChange={vehicleMatchOnChange}
              items={truckDataYears}
              placeholder="Year"
              name="towingVehicle.year"
              icon={isMobile ? null : ['far', 'calendar-alt']}
              id="towing_vehicle_year"
              data-testid="towing_vehicle_year"
            />
            <DownshiftAutocomplete
              selectedValue={values.towingVehicle.make}
              handleChange={vehicleMatchOnChange}
              items={makes}
              loading={makesLoading}
              placeholder="Make"
              name="towingVehicle.make"
              icon={isMobile ? null : ['far', 'car']}
              disabled={disableMakeModel}
              id="towing_vehicle_make"
              data-testid="towing_vehicle_make"
              hideClearSelection
            />
            <DownshiftAutocomplete
              selectedValue={values.towingVehicle.model}
              handleChange={vehicleMatchOnChange}
              items={models}
              loading={modelsLoading}
              placeholder="Model"
              name="towingVehicle.model"
              icon={isMobile ? null : ['far', 'car']}
              disabled={disableModel}
              id="towing_vehicle_model"
              data-testid="towing_vehicle_model"
              hideClearSelection
            />
          </VehicleTowingMatchWrapper>
          <ModelYearDisclaimer year={values?.towingVehicle?.year} marginBottomNeeded />
        </>
      )}

      <ButtonWrapper>
        <SubmitButton
          variant="primaryLarge"
          onClick={handleClick}
          data-id="home-inputs-search"
          type="submit"
          bg={buttonColor}
          aria-label="Search"
          className="HomeSubmitButton"
          disabled={disableSubmit}
        >
          {searchType === 'drivables'
            ? 'Search for Drivable RVs'
            : searchType === 'towables'
              ? 'Search for Towable RVs'
              : searchType === 'delivery'
                ? 'Search for Deliverable RVs'
                : 'Search for all RVs'}
        </SubmitButton>
      </ButtonWrapper>
    </InputWrapper>
  );
}

export default withHitchTheme(connect(Inputs));
