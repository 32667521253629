import React from 'react';
import styled from 'styled-components';
import { withTravelPreferences } from 'core/state/Globals';

import desktopBackgroundSm from 'images/home/hero_959.jpg';
import desktopBackgroundMed from 'images/home/hero_1440.jpg';
import desktopBackgroundLg from 'images/home/hero_1920.jpg';
import SearchBox from 'search/components/SearchFields/SearchBox';

import RentalGuarantee from 'components/RentalGuarantee';
import ValueProps from './ValueProps';
import TravelPlans from './TravelPlans';

const BackGround = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 1em 0 0 0;
  ${({ theme }) => theme.medium`
    background-image: url(${desktopBackgroundSm});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 735px;
    flex-direction: row;
    justify-content: start;
    padding: 0 0 0 1.875rem;
  `};
  ${({ theme }) => theme.large`
    background-image: url(${desktopBackgroundMed});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  `};
  ${({ theme }) => theme.xlarge`
    background-image: url(${desktopBackgroundLg});
  `};
`;

function Hero({ travelPreferences }) {
  const { destination, travelDates, numberOfTravelers, towingVehicle } = travelPreferences;

  return (
    <>
      <BackGround>
        <SearchBox
          destination={destination}
          travelDates={travelDates}
          numberOfTravelers={numberOfTravelers}
          towingVehicle={towingVehicle}
          submitOnChange={false}
          buttonColor="niceBlue"
        />
      </BackGround>
      <RentalGuarantee
        variant="home-page"
        label="Home page"
      />
      <ValueProps />
      <TravelPlans />
    </>
  );
}

export default withTravelPreferences(Hero);
