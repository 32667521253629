import React from 'react';
import styled from 'styled-components';

const Icon = styled.svg``;

export default function Shield() {
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg>
        <path
          d="M10 2C10.1438 2 10.2875 2.03125 10.4188 2.09063L16.3031 4.5875C16.9906 4.87813 17.5031 5.55625 17.5 6.375C17.4844 9.475 16.2094 15.1469 10.8219 17.725C10.3 17.975 9.69376 17.975 9.17189 17.725C3.79064 15.1469 2.51564 9.475 2.50001 6.375C2.49689 5.55625 3.00939 4.87813 3.69689 4.5875L9.58439 2.09063C9.71251 2.03125 9.85626 2 10 2ZM10 4.0875V15.9C14.3125 13.8125 15.4719 9.19062 15.5 6.41875L10 4.0875Z"
          fill="#004665"
        />
      </svg>
    </Icon>
  );
}
