import React from 'react';
import styled from 'styled-components';

import { useDevice } from 'core/hooks/useDevice';

import { Tooltip } from 'components/Tippy';
import SafeTracking from 'tracking/SafeTracking';
import Shield from 'images/icons/NewBrandShield';

// The homepage uses Bootstrap, the checkout and listing details page use Basscss, instead
// of adding another import to both pages or writing 2 separate components, I wrote out the CSS


const Row = styled.div`
  line-height: 1.25;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  position: relative;
  @media (min-width: 992px) {
    width: auto;
    max-width: 100%;
  }
`;

const Box = styled(Row)`
  flex-wrap: wrap;
  font-size: 14px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const Headline = styled(Row)`
  margin-right: 0;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 0;
  @media (min-width: 576px) {
    font-size: 20px;
    margin-right: 1rem;
    padding-bottom: 2px;
  }
  svg {
    margin-right: 0.25rem;
  }
`;

export const TextButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: #0a2239;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }

  ${props => props.theme.medium`
    color: ${props.theme.colors.linkColor};
    text-decoration: none;
  `}
`;

const Message = styled.div`
  padding: 1.5rem;
  text-align: left;
  a {
    color: #377ac9;
  }
`;

const onShown = (label) => {
  SafeTracking.track(`${label} Worry-Free Rental Guarantee Learn More view`);
};

const trackLinkClick = (label) => {
  SafeTracking.track(`${label} Worry-Free Rental Guarantee link click`);
};

export const PopoverMessage = (label) => {
  return (
    <Message>
      RVshare connects millions of renters to the perfect RV rental and provides a safe, secure
      booking experience. When you book and pay on RVshare, our&nbsp;
      <a href="/rental-guarantee" target="_blank" onClick={() => trackLinkClick(label)}>
        Worry-Free Rental Guarantee
      </a>
      &nbsp;protects your payment, security deposit, and more should anything go wrong.
    </Message>
  );
};

export const Popover = (label) => {
  return (
    <Tooltip
      content={PopoverMessage(label)}
      placement="bottom"
      interactive
      onShown={() => onShown(label)}
      duration={100}
      theme="light-border"
      trigger="click"
    >
      <TextButton
        type="button"
        data-event-category="RV Details Page"
        data-event-action="Learn more"
        data-event-label="Worry-Free Rental Guarantee"
      >
        Learn more
      </TextButton>
    </Tooltip>
  );
};

function RentalGuarantee({ className, variant, label }) {
  const { isMobile } = useDevice();
  const shouldHide = (variant === 'home-page') && !isMobile;

  if (shouldHide) { return null; }
  return (
    <div className={className}>
      <div className={variant}>
        <Box>
          <Headline>
            {!isMobile && (<Shield />)}
            <span className="headline">Worry-Free Rental Guarantee</span>
          </Headline>
          {variant === 'home-page' || variant === 'how-it-works' ? (
            <>
              <Row>Guaranteed protection for your trip, every step of the way.&nbsp;</Row>
              <Row>{Popover(label)}</Row>
            </>
          ) : (
            <Row className='tag'>
              Book safely and securely and rent worry-free.&nbsp;
              {Popover(label)}
            </Row>
          )}
        </Box>
      </div>
    </div>
  );
}

export default styled(RentalGuarantee)`
  .home-page {
    background-color: #f4f4f4;
    border-radius: 0;

    @media (min-width: 576px) {
      border-radius: 9px;
    }
  }

  .how-it-works {
    background-color: #112741;
    color: #fff;
    border-radius: 0;
    @media (min-width: 576px) {
      border-radius: 9px;
    }
    button {
      color: #fff;
      text-decoration: underline;
    }
    svg {
      .cls-1 {
        fill: #fff;
        fill-rule:evenodd;
      }
      .cls-2 {
        fill: #112741;
      }
    }
  }

  .details {
    background-color: #fff;
    border: solid 1px #d5d5d5;
    border-radius: 4px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);

    .headline {
      color: ${props => props.theme.colors.primaryBlue};
      font-size: 1rem;
      font-weight: 700;
    }

    .tag {
      margin-top: 8px;
      font-size: 0.875rem;
      display: flex;
      align-items: baseline;
    }
  }

  .details-mobile,
  .checkout-mobile {
    background-color: ${props => props.theme.colors.grey100};
    margin-right: 0;
    margin-left: 0;
  }

  .checkout {
    border-radius: 4px;
    background-color: ${props => props.theme.colors.grey100};

    ${props => props.theme.medium`
      background-color: #e9f0f5;
    `}
  }
`;
