import React from 'react';

import InLineAd from 'components/Ads/InLineAd';

function TravelPlans() {
  return (
    <section className="container travel-plans" data-analytics-module="RVs for All Your Travel Needs">
      <h2 className="home__heading">RVs for All Your Travel Needs</h2>
      <div className="row travel-plans__row">
        <div className="col-6 col-md-6 col-lg-4 left-col">
          <a
            href="/rv-rental?pets=true"
            data-track="click"
            data-action="Filter Link Click"
            data-category="Link Click"
            data-label="Home"
            data-filter="Pet Friendly"
            data-event-category="Homepage"
            data-event-action="Pet Friendly RVs"
            data-event-label="RVs for All Your Travel Needs"
          >
            <div className="travel-plans__img travel-plans_pet-friendly" />
            <div className="travel-plans__text">
              <h4>Pet Friendly RVs</h4>
              <p>Take your furry friends along on your next big adventure</p>
            </div>
          </a>
        </div>
        <div className="col-6 col-md-6 col-lg-4 d-lg-flex justify-content-center right-col">
          <a
            href="/rv-rental?delivery=true"
            data-track="click"
            data-action="Filter Link Click"
            data-category="Link Click"
            data-label="Home"
            data-filter="Delivery"
            data-event-category="Homepage"
            data-event-action="Deliverable RVs"
            data-event-label="RVs for All Your Travel Needs"
          >
            <div className="travel-plans__img travel-plans__deliverable" />
            <div className="travel-plans__text">
              <h4>Deliverable RVs</h4>
              <p>Enjoy the added convenience of having your RV delivered anywhere</p>
            </div>
          </a>
        </div>
        <div className="col-6 col-md-6 d-sm-block d-md-block d-lg-none left-col">
          <a
            href="/rv-rental?rv_class=Travel%20Trailer%2CFifth%20Wheel%2CToy%20Hauler%2CFolding%20Trailer"
            data-track="click"
            data-action="Filter Link Click"
            data-category="Link Click"
            data-label="Home"
            data-filter="Towable"
            data-event-category="Homepage"
            data-event-action="Towable RVs"
            data-event-label="RVs for All Your Travel Needs"
          >
            <div className="travel-plans__img travel-plans__towable" />
            <div className="travel-plans__text">
              <h4>Towable RVs</h4>
              <p>Bring your home away from home along with a towable RV</p>
            </div>
          </a>
        </div>
        <div className="col-6 col-md-6 d-sm-block d-md-block d-lg-none right-col">
          <a
            href="/rv-rental?rv_class=Class%20A%20Motor%20Home%2CClass%20B%20Camping%20Van%2CClass%20C%20Motor%20Home"
            data-track="click"
            data-action="Filter Link Click"
            data-category="Link Click"
            data-label="Home"
            data-filter="Drivable"
            data-event-category="Homepage"
            data-event-action="Drivable RVs"
            data-event-label="RVs for All Your Travel Needs"
          >
            <div className="travel-plans__img travel-plans__drivable" />
            <div className="travel-plans__text">
              <h4>Drivable RVs</h4>
              <p>Travel to your destination in comfort with a drivable RV</p>
            </div>
          </a>
        </div>
        <div className="col-12 col-md-12 col-lg-4 pb-4">
          <div className="travel-plans__inline_ad">
            <InLineAd 
              path="/116851855/rv/hp"
              targeting={{ ps: ['b', 1]}}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TravelPlans;
