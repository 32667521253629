import React, { useCallback, useState } from 'react';

import {
  saveTravelDates,
  saveTravelDestination,
  saveNumberOfTravelers,
  saveTowingVehicle,
  updateTowingVehicle,
} from 'core/helpers/TravelPreferences';
import { useDevice } from 'core/hooks/useDevice';
import {
  DrivableClassFilterOptions,
  TowableClassFilterOptions,
  classOptionsArrayToBoolObject,
} from 'core/helpers/RV';

import { buildSearchUrl } from '../../../helpers/Filters';

import { SearchBoxContainer, Header } from './styles';
import SearchTypeToggle from './SearchTypeToggle';
import Inputs from './Inputs';
import SearchForm from '../SearchForm';
import RvFinderLink from './RvFinderLink';

function SearchBox(props) {
  const { isMobile } = useDevice();
  const [searchType, setSearchType] = useState('all_rvs');
  const { displayHeader = true } = props;

  // Some things are consistent across all submissions
  // so we'll wrap any custom onSubmit here
  const handleSubmit = useCallback(
    values => {
      saveTravelDates(values.travelDates);
      saveTravelDestination(values.destination);
      saveNumberOfTravelers(values.numberOfTravelers);
      saveTowingVehicle(searchType === 'towables' ? values.towingVehicle : null);

      // default submission is to redirect to search page
      const buildURL = buildSearchUrl({
        ...values.destination,
        ...values.travelDates,
        sleeps: values.numberOfTravelers,
        ...(searchType === 'towables' && {
          rvClass: classOptionsArrayToBoolObject(TowableClassFilterOptions),
          ...(values.towingVehicle?.towingWeight && {
            towingVehicle: {
              towingWeight: values.towingVehicle.towingWeight,
            },
          }),
        }),
        ...(searchType === 'drivables' && {
          rvClass: classOptionsArrayToBoolObject(DrivableClassFilterOptions),
        }),
        ...(searchType === 'delivery' && {
          delivery: true,
        }),
      });

      window.location.href = buildURL;
    },
    [searchType]
  );

  // we always want to watch for changes and save updated travel preferences
  const handleChange = useCallback((key, value) => {
    // The DatePickerModal is not a formik field. It returns only an object
    // Gross workaround until we refactor
    if (key?.startDate && key?.endDate) {
      saveTravelDates(key);
    }
    if (key === 'travelDates') {
      saveTravelDates(value);
    } else if (key === 'destination') {
      saveTravelDestination(value);
    } else if (key === 'numberOfTravelers') {
      saveNumberOfTravelers(value);
    } else if (key.includes('towingVehicle')) {
      updateTowingVehicle(key, value);
    }
  }, []);

  return (
    <div>
      <SearchBoxContainer>
        {displayHeader && <Header>Find the Perfect RV Rental</Header>}
        <SearchTypeToggle
          isMobile={isMobile}
          searchType={searchType}
          setSearchType={setSearchType}
          styles={props.styles}
        />
        <SearchForm
          {...props}
          inputs={Inputs}
          onChange={handleChange}
          onSubmit={handleSubmit}
          searchType={searchType}
        />
      </SearchBoxContainer>
      <RvFinderLink />
    </div>
  );
}

export default React.memo(SearchBox);
