import React from 'react';
import { Bling as GPT } from 'react-gpt';

GPT.enableSingleRequest();

export default function InLineAd({ className, path, sizeMapping, targeting, ...props }) {
  return (
    <div className={className}>
      <GPT
        adUnitPath={path}
        sizeMapping={[
          // Only have one size currently
          { viewport: [0, 0], slot: [300, 250] },
        ]}
        targeting={targeting}
        collapseEmptyDiv
        viewableThreshold={0.01}
        {...props}
      />
    </div>
  );
}
